#projects-container{
  display: grid;
  grid-template-columns: repeat(2, 50%);

}
.project{
  border: 1px solid #e4e4e4;
  margin: 10px;
  border-radius: 12px;
  overflow: hidden;
  max-height: min-content;
}
@media only screen and (max-width: 600px) {
  #projects-container{
    display: grid;
    grid-template-columns: repeat(1, 100%);
  }
}
.project-video{
  margin: auto;
  max-width: 100%;
}
.project-image{
  margin: auto;
  max-width: 100%;
}
.project-details{
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.project-title{
  font-weight: 500;
  font-size: 1.3rem;
  padding-bottom: 10px;
}
.project-description{
  font-weight: 300;
  opacity: 0.9;
  font-size: 0.95rem;
}
.tags{
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-wrap: nowrap;
}
.tags::-webkit-scrollbar {
  display: none;
}
.tag{
  margin: 5px;
  padding: 7px 10px;
  border-radius: 20px;
  background-color: #e4e4e4;
  flex-shrink:0;
  font-size: 0.8rem;
  font-weight: 600;
  color: #6b6b6b;
  border: 0px;
  outline: none;
}
.tag:hover{
  background-color: #c4c4c4;
  cursor: pointer;
}

#tag-options{
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-wrap: wrap;
  justify-content: center;
}
#tag-options::-webkit-scrollbar {
  display: none;
}
.tag-option{
  max-width: fit-content;
  margin: 5px;
  padding: 7px 10px;
  border-radius: 20px;
  background-color: #e4e4e4;
  flex-shrink:0;
  font-size: 0.8rem;
  font-weight: 600;
  color: #6b6b6b;
  border: 0px;
  outline: none;
}
.tag-option:hover{
  filter: brightness(0.9);
}
#show-tags-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#show-tags{
  padding: 10px 10px 0px 10px;
  width: fit-content;
  border-radius: 20px;
  background-color: #ffffff;
  flex-shrink:0;
  font-size: 1rem;
  font-weight: 600;
  color: #6b6b6b;
  border: 0px;
  outline: none;
  margin: auto;
}
#down-arrow{
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  min-height: 25px;
  margin: auto;
  filter: contrast(0.2);
  background: url('../../../images/down.gif');
  transform: rotate(0deg);
}
.links{
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.links::-webkit-scrollbar {
  display: none;
}
.link{
  margin: 5px;
  padding: 8px 10px;
  border-radius: 20px;
  background-color: #e4e4e4;
  flex-shrink:0;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
}
.link:hover{
  background-color: #c4c4c4;
}
.link>a{
  font-weight: 600;
  color: #6b6b6b;
  text-decoration: none;
}
.GitHub-image{
  background-image: url(../../../images/GitHub.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  min-width: 15px;
  min-height: 15px;
}
.Website-image{
  background-image: url(../../../images/Website.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  min-width: 15px;
  min-height: 15px;
}
.Demo-image{
  background-image: url(../../../images/Demo.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  min-width: 15px;
  min-height: 15px;
}
.APK-image{
  background-image: url(../../../images/APK.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  min-width: 15px;
  min-height: 15px;
}
.Notebook-image{
  background-image: url(../../../images/jupyter.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  min-width: 15px;
  min-height: 15px;
}