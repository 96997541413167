body{
  
}
#root{
  min-height: 100vh;
}
#container{
  display: grid;
  grid-template-columns: repeat(4, 25vw);
  min-height: 100vh;
}
#profile-container{
  margin: 5px;
  background-color: #f4f4f4;
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  min-width: 25vw;
}
#details{
  background-color: #fff;
  grid-column-start: 2;
  grid-column-end: 6;
}
#profile-info{
  position: fixed;
  margin-top: 23vh;
  width: 20vw;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
#profile-image{
  width: 11rem;
  min-height: 11rem;
  margin: auto;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  animation: fade-in 0.2s both ease-in;
  animation-delay: 0.5s;
}
#content{
  margin: auto;
  width: 70%;
}
#content>h3{
  text-align: center;
  color: #1e385e;
  font-weight: 300;
  margin: 5px 0px;
}
#content>h4{
  text-align: center;
  font-size: 0.9rem;
  color: #1e385e;
  font-weight: 300;
  margin: 5px 0px;
}
#content>h5{
  text-align: center;
  font-size: 0.75rem;
  color: #3c4e69;
  font-weight: 300;
  margin: 5px 0px;
}
@media only screen and (max-height: 500px) {
  #profile-info{
    margin-top: 15vh;
  }
  #profile-image{
    width: 10rem;
    min-height: 10rem;
  }
}
@media only screen and (max-height: 300px) {
  #profile-image{
    width: 7rem;
    min-height: 7rem;
  }
}
@media only screen and (max-width: 1000px) {
  #profile-image{
    width: 8rem;
    min-height: 8rem;
  }
  #content>h4{
    text-align: center;
    font-size: 0.8rem;
    color: #1e385e;
    font-weight: 300;
    margin: 5px 0px;
  }
}
@media only screen and (max-width: 800px) {
  #profile-image{
    width: 6rem;
    min-height: 6rem;
  }
}
#about-social{
  display: flex;
  width: 100%;
  float: right;
  justify-content: space-between;
}
#gmail-image{
  margin: 8px 0px;
  min-height: 34px;
  min-width: 34px;
  background-image: url('../../images/gmail-l.png');
}
.social-icon-about{
  min-width: 30px;
  min-height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 0px;
  filter: brightness(70%);
}
.social-icon-about:hover{
  transition: 0.2s;
  filter: brightness(50%);
}
@media only screen and (max-width: 600px) {
  #profile-container{
    margin: auto;
    grid-column-start: 1;
    grid-column-end: 6;
    max-height: 230px;
    min-width: 95%;
    margin-top: 5px;
  }
  #details{
    grid-column-start: 1;
    grid-row-start: 2;
  }
  #profile-info{
    position: relative;
    margin: 0px;
    flex-direction: row;
    justify-content: space-between;
    min-width: 90%;
    min-height: 160px;
  }
  #profile-image{
    min-width: 8rem;
    max-width: 8rem;
    min-height: 8rem;
  }
  #content>h3{
    text-align: right;
    color: #1e385e;
    font-weight: 300;
    margin: 2px 0px;
  }
  #content>h4{
    text-align: right;
    margin: 2px 0px;
  }
  #content>h5{
    text-align: right;
  }
  #menu{
    margin-top: 10px;
  }
  #about-social{
    width: 60%;
  }
}
hr{
  border-top: 1px solid #b2b2b2;
  border-bottom: none;
}


#menu{
  margin: auto;
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.menu-items{
  background-color: #f5f5f5;
  font-size: 16px;
  font-weight: 300;
  border-radius: 20px;
  padding: 10px 15px 10px 15px;
  outline: none;
  border: none;
  width: 120px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.menu-items:hover{
  background-color: #f5f5f5;
}
#render-area{
  margin: 10px;
  padding: 30px;
  border-radius: 12px;
}
@media only screen and (max-width: 600px) {
  .menu-items{
    font-size: 14px;
    width: fit-content;
  }
  #render-area{
    margin: 5px;
    padding: 10px;
    min-height: 70vh;
  }
}

