#art-container{
  width: 100vw;
  height: 100vh;
  display: flex;
}
#art{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
#opinion-bar{
  margin: auto;
  min-height: 30px;
  min-width: 100%;
  position: fixed;
  z-index: 2;
  bottom: 25px;
  display: flex;
  justify-content: center;
}
#opinion{
  background-color: #fff;
  box-shadow: 0px 0px 10px #555;
  border-radius: 25px;
  position: relative;
  border: none;
  min-width: 40%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  #opinion{
    min-width: 80%;
  }
}
#opinion-input{
  border: none;
  outline: none;
  padding: 15px 10px;
  border-radius: 25px;
  width: 100%;
}
#opinion-submit{
  border: none;
  outline: none;
  max-width: 100px;
  padding: 15px 10px;
  border-radius: 25px;
  background-color: #f5f5f5;
  color: #5f5f5f;
  cursor: pointer;
}
#opinion-submit:hover{
  background-color: #e2e2e2;
}
#thanks{
  background-color: #fff;
  padding: 7px 20px 10px 20px;
  border-radius: 25px;
}
#opinion-bar.fade-away{
  animation: fade-away 1s both ease;
  animation-delay: 2s;
}
@keyframes fade-away{
  from{
    bottom: 25px;
    opacity: 1;
  }to{
    bottom: 0px;
    opacity: 0;
  }
}