.image-element{
  max-width: 98%;
  padding: 3px;
  border-radius: 8px;
}
.image-element:hover{
  transform: scale(1.02);
  transition: 0.2s;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .image-element{
    max-width: 100%;
  }
}
#artworks-container{
  margin-top: 10px;
}
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -20px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px;
  background-clip: padding-box;
}
